import React, { useState, useEffect } from "react";
import {
  Drawer,
  IconButton,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Collapse as MuiCollapse,
  Switch,
  styled,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import StyledTooltip from "./StyledTooltip";
import { ReactComponent as Radio } from "../../Assets/Svgs/radio.svg";
import {
  ExpandLess,
  ExpandMore,
  AddCircleOutline as AddCircleIcon,
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon,
  Error as ErrorIcon,
  Logout,
} from "@mui/icons-material";

import Edit from "@mui/icons-material/EditOutlined";
import HelpIcon from "@mui/icons-material/Help";
import { useNavigate } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";
import { useQuery } from "@apollo/client";
import {
  GET_ALL_USER_PROBLEMS,
  GET_USER_ORGANIZATIONS,
  GET_ALL_SPRINTS,
} from "../../Graphql/User/Query";
import logo from "../../Assets/Images/anplogo.png";
import { NavLink } from "react-router-dom";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  width: 58,
  height: 32,
  padding: 0,
  display: "flex",
  "&:hover": { backgroundColor: "transparent" },
  "& .MuiSwitch-switchBase": {
    padding: 3,
    "&.Mui-checked": {
      transform: "translateX(28px)",
      color: "#65558F",
      "& + .MuiSwitch-track": { backgroundColor: "#65558F" },
    },
    "&:hover": { backgroundColor: "transparent" },
  },
  "& .MuiSwitch-thumb": {
    padding: 13,
    backgroundColor: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  "& .MuiSwitch-track": {
    borderRadius: 15,
    backgroundColor: "#65558F",
    opacity: 1,
  },
}));

const Sidebar = ({ open, setOpen }) => {
  const [openMenus, setOpenMenus] = useState({});
  const [profileMenuAnchor, setProfileMenuAnchor] = useState(null);
  const [selectedMenu, setSelectedMenu] = useState(
    localStorage.getItem("selectedMenu")
  );
  const [view, setView] = useState("Admin");
  const [groupedProblems, setGroupedProblems] = useState({});
  const [userSprintsGrouped, setUserSprintsGrouped] = useState({});

  const navigate = useNavigate();
  const handleViewToggle = () => setView(view === "Admin" ? "Sprint" : "Admin");

  // Fetch user problems
  const { data: problemData, loading: problemLoading } = useQuery(
    GET_ALL_USER_PROBLEMS,
    {
      variables: { userId: localStorage.getItem("userId") },
    }
  );

  // Fetch user's organizations
  const { data: orgData, loading: orgLoading } = useQuery(
    GET_USER_ORGANIZATIONS,
    {
      variables: { userId: localStorage.getItem("userId") },
    }
  );

  // Fetch all sprints
  const { data: sprintData, loading: sprintLoading } =
    useQuery(GET_ALL_SPRINTS);

  useEffect(() => {
    // Reset selected menu on component load
    setSelectedMenu(null);
    localStorage.removeItem("selectedMenu");
    localStorage.removeItem("openMenus");
  }, []);

  useEffect(() => {
    const savedOpenMenus = JSON.parse(localStorage.getItem("openMenus")) || {};
    setOpenMenus(savedOpenMenus);
  }, []);

  // Group problems by orgId and attach org name for Admin view
  useEffect(() => {
    if (orgData && problemData) {
      // Start by building a structure from the user's organizations
      const grouped = {};

      orgData.getUserOrganizations.forEach((org) => {
        // Find all problems that belong to this organization
        const matchedProblems = problemData.getAllUserProblems.filter(
          (problem) => problem.orgId === org.id
        );

        grouped[org.id] = {
          orgName: org.name || "Unknown Organization",
          problems: matchedProblems,
        };
      });

      setGroupedProblems(grouped);
    }
  }, [problemData, orgData]);

  // Group sprints by problem name for Sprint view
  useEffect(() => {
    if (sprintData && sprintData.getAllSprints) {
      const userId = localStorage.getItem("userId");

      const groupedSprints = sprintData.getAllSprints
        .filter((sprint) => sprint.sprinterId === userId)
        .reduce((acc, sprint) => {
          const { problemId, problemName } = sprint;
          if (!acc[problemName]) {
            acc[problemName] = { problemId, sprints: [] };
          }
          acc[problemName].sprints.push(sprint);
          return acc;
        }, {});

      setUserSprintsGrouped(groupedSprints);
    }
  }, [sprintData]);

  const toggleSidebar = () => {
    const newSidebarState = !open;
    setOpen(newSidebarState);
    localStorage.setItem("sidebarOpen", newSidebarState);
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error signing out", error);
    }
  };

  const handleProfileClick = (event) =>
    setProfileMenuAnchor(event.currentTarget);
  const handleCloseProfileMenu = () => setProfileMenuAnchor(null);
  const handleEditProfile = () => {
    navigate("/profile");
    handleCloseProfileMenu();
  };
  const handleCreateOrg = () => {
    navigate("/organizations");
    handleCloseProfileMenu();
  };
  const toggleMenu = (menuId, problemId, sprint = null) => {
    const updatedMenus = {
      ...openMenus,
      [menuId]: !openMenus[menuId],
    };
    setOpenMenus(updatedMenus);
    localStorage.setItem("openMenus", JSON.stringify(updatedMenus));
    console.log(problemId, sprint);

    // Only pass `sprintfromsidebar` when it’s a sprint click (navigation logic unchanged)
    if (sprint) {
      navigate("/nudgebuilder/custombuilder", {
        state: { sprintfromsidebar: sprint, problemId },
      });
    } else {
      navigate("/nudgebuilder/custombuilder", {
        state: { sprintfromsidebar: null, problemId },
      });
    }
  };

  const calculateSprintProgress = (sprint) => {
    const today = new Date();
    const startDate = new Date(sprint.startDate);
    const endDate = new Date(sprint.endDate);

    if (today < startDate) return 0;
    if (today > endDate) return 100;

    const totalDays = (endDate - startDate) / (1000 * 60 * 60 * 24);
    const elapsedDays = (today - startDate) / (1000 * 60 * 60 * 24);

    return Math.min((elapsedDays / totalDays) * 100, 100);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "In Progress":
        return "#FFBD2E";
      case "Success":
        return "#8BC34A";
      case "Terminated":
        return "#B3261E";
      default:
        return "#FFBD2E";
    }
  };

  const getUniqueSprinters = (problem) => {
    const sprinters = [];
    problem.children.forEach((stakeholder) => {
      stakeholder.children.forEach((behavior) => {
        behavior.children.forEach((nudge) => {
          nudge.children.forEach((sprinter) => {
            if (!sprinters.find((s) => s.sprinterId === sprinter.sprinterId)) {
              sprinters.push(sprinter);
            }
          });
        });
      });
    });
    return sprinters;
  };

  const getSprintsBySprinter = (problemId, sprinterName) => {
    return sprintData?.getAllSprints.filter(
      (sprint) =>
        sprint.problemId === problemId && sprint.sprinterName === sprinterName
    );
  };

  // Reintroduce the calculateCompletion function for problem-level progress
  const calculateCompletion = (problem) => {
    let totalNudgeTypes = 0;
    let nudgeTypesWithSprints = 0;
    let totalProgress = 0;

    if (!sprintData) return 0;

    problem.children.forEach((stakeholder) => {
      stakeholder.children.forEach((behavior) => {
        behavior.children.forEach((nudge) => {
          totalNudgeTypes += 1;
          const hasSprint = sprintData?.getAllSprints.some(
            (s) => s.problemId === problem.id && s.nudgeId === nudge.id
          );
          if (!hasSprint) {
            totalProgress += 0;
          } else {
            nudgeTypesWithSprints += 1;
            const sprintProgress = sprintData.getAllSprints
              .filter(
                (s) => s.problemId === problem.id && s.nudgeId === nudge.id
              )
              .reduce((sum, s) => sum + calculateSprintProgress(s), 0);
            totalProgress += sprintProgress / nudgeTypesWithSprints;
          }
        });
      });
    });

    const completionPercentage =
      totalNudgeTypes === 0 ? 0 : totalProgress / totalNudgeTypes;
    return completionPercentage !== 0 ? completionPercentage : 10;
  };

  if (problemLoading || orgLoading || sprintLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <CircularProgress />
      </div>
    );
  }

  return (
    <div
      className={`font-roboto text-base ${
        profileMenuAnchor ? "blur-background" : ""
      }`}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: open ? "20%" : "90px",
          transition: "width 0.3s",
          "& .MuiDrawer-paper": {
            width: open ? "20%" : "90px",
            overflowX: "hidden",
            transition: "width 0.3s",
          },
        }}
      >
        {/* Top Section */}
        <div className="flex flex-row justify-between items-center bg-primary p-4">
          <img src={logo} className="w-[40px] h-[36px]" alt="Logo" />
          <StyledTooltip title={`Expand-Collapse Sidebar`}>
            <IconButton onClick={toggleSidebar}>
              {open ? (
                <ChevronLeftIcon className="text-white" />
              ) : (
                <ChevronRightIcon className="text-white" />
              )}
            </IconButton>
          </StyledTooltip>
        </div>

        {/* Profile Section */}
        {open ? (
          <div className="w-full flex justify-center items-center -mt-7">
            <StyledTooltip title={`Profile Settings`}>
              <img
                src={localStorage.getItem("profilepic")}
                className={`w-[58px] h-[58px] rounded-full shadow p-[1px] bg-white cursor-pointer ${
                  profileMenuAnchor
                    ? "border-4 border-[#6750A4]"
                    : "border-2 border-white"
                }`}
                alt="Profile"
                onClick={handleProfileClick}
              />
            </StyledTooltip>
          </div>
        ) : (
          <div className="w-full flex justify-center items-center mt-1">
            <StyledTooltip title={`Profile Settings`}>
              <img
                src={localStorage.getItem("profilepic")}
                className={`w-[50px] h-[50px] rounded-full shadow p-[1px] bg-white cursor-pointer ${
                  profileMenuAnchor
                    ? "border-4 border-[#6750A4]"
                    : "border-2 border-white"
                }`}
                alt="Profile"
                onClick={handleProfileClick}
              />
            </StyledTooltip>
          </div>
        )}

        {/* Profile Menu */}
        <Menu
          anchorEl={profileMenuAnchor}
          open={Boolean(profileMenuAnchor)}
          onClose={handleCloseProfileMenu}
          PaperProps={{ style: { backgroundColor: "#F3EDF7" } }}
        >
          <MenuItem onClick={handleEditProfile} style={{ fontSize: "16px" }}>
            <Edit className="mr-2" /> Edit Profile
          </MenuItem>
          <MenuItem onClick={handleCreateOrg} style={{ fontSize: "16px" }}>
            <BusinessIcon className="mr-2" /> Organization
          </MenuItem>
          <MenuItem onClick={handleLogout} style={{ fontSize: "16px" }}>
            <Logout className="mr-2" /> Logout
          </MenuItem>
        </Menu>

        {/* Main Action Button */}
        <div className="px-3 w-full flex flex-col space-y-5">
          {open ? (
            <NavLink to="/">
              <button className="w-full h-14 bg-[#e8def8] rounded-full flex items-center justify-center px-4 text-[#4a4459] font-semibold mt-5">
                <AddCircleIcon className="mr-2" />
                <StyledTooltip title={`Generate Problem Statement`}>
                  Generate Problem Statement
                </StyledTooltip>
              </button>
            </NavLink>
          ) : (
            <NavLink to="/">
              <div className="bg-[#e8def8] p-1 rounded-full mt-2 w-[65px] h-[56px] flex justify-center items-center">
                <StyledTooltip title={`Generate Problem Statement`}>
                  <AddCircleIcon />
                </StyledTooltip>
              </div>
            </NavLink>
          )}
          {open && (
            <div className="text-center mt-2 mb-2">
              {(() => {
                const selectedOrgId = localStorage.getItem("orgId");
                const selectedOrgName =
                  selectedOrgId && groupedProblems[selectedOrgId]
                    ? groupedProblems[selectedOrgId].orgName
                    : "No Organization Selected";
                return (
                  <h2 className="text-base p-2 bg-bcolor text-white rounded-full my-2">
                    Organization : {selectedOrgName}
                  </h2>
                );
              })()}
            </div>
          )}

          {/* Toggle View */}
          <div className="pb-3">
            {open ? (
              <div className="flex flex-row justify-center w-full items-center px-2 space-x-2">
                <h3
                  className={`text-base font-semibold ${
                    view === "Admin" ? "text-[#65558F]" : "text-[#79747E80]"
                  } m-0`}
                >
                  Admin View
                </h3>
                <CustomSwitch
                  checked={view === "Sprint"}
                  onChange={handleViewToggle}
                />
                <h3
                  className={`text-base font-semibold ${
                    view === "Sprint" ? "text-[#65558F]" : "text-[#79747E80]"
                  } m-0`}
                >
                  Sprinter View
                </h3>
              </div>
            ) : (
              <div className="text-bcolor flex flex-row space-x-2">
                <h1>{view}</h1> <Radio />
              </div>
            )}
          </div>
        </div>

        {/* Hierarchical List depending on view */}
        {problemLoading || orgLoading || sprintLoading ? (
          <div className="flex justify-center items-center h-full">
            <CircularProgress />
          </div>
        ) : (
          <div className="overflow-y-auto h-[57%] mt-4">
            {view === "Admin" ? (
              <List component="nav" className="flex flex-col space-y-1">
                {Object.entries(groupedProblems).map(
                  ([orgId, { orgName, problems }]) => (
                    <div key={orgId}>
                      {/* Organization Level */}
                      <ListItem
                        button
                        onClick={() => {
                          // Toggle the menu for orgId
                          setOpenMenus((prev) => ({
                            ...prev,
                            [orgId]: !prev[orgId],
                          }));

                          // Store the orgId in localStorage instead of navigating
                          localStorage.setItem("orgId", orgId);
                        }}
                      >
                        {open && <ListItemText primary={orgName} />}
                        {openMenus[orgId] ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <MuiCollapse
                        in={openMenus[orgId]}
                        timeout="auto"
                        unmountOnExit
                      >
                        <List component="div" disablePadding>
                          {problems.map((problem) => {
                            const uniqueSprinters = getUniqueSprinters(problem);
                            const completionPercentage =
                              calculateCompletion(problem);

                            return (
                              <div key={problem.id}>
                                <ListItem
                                  button
                                  sx={{
                                    "&:hover": {
                                      backgroundColor: "#65558f", // or any color you prefer
                                    },
                                  }}
                                  className={`flex space-x-2 items-center cursor-pointer ${
                                    openMenus[`problem-${problem.id}`]
                                      ? "bg-bcolor rounded-lg text-white"
                                      : "text-[#4a4459]"
                                  } ${
                                    open ? "justify-start" : "justify-center"
                                  }`}
                                  onClick={() => {
                                    setSelectedMenu(`problem-${problem.id}`);
                                    toggleMenu(
                                      `problem-${problem.id}`,
                                      problem.id
                                    );
                                  }}
                                >
                                  {open && (
                                    <div className="flex items-center justify-between  space-x-2 w-full">
                                      <CircularProgress
                                        variant="determinate"
                                        value={completionPercentage}
                                        size={30}
                                        thickness={5}
                                        style={{
                                          color:
                                            selectedMenu ===
                                            `problem-${problem.id}`
                                              ? "#ffffff"
                                              : "#65558f",
                                        }}
                                      />
                                      <ListItemText
                                        primary={
                                          problem.name.substring(0, 20) + "..."
                                        }
                                      />
                                    </div>
                                  )}
                                  {openMenus[`problem-${problem.id}`] ? (
                                    <ExpandLess />
                                  ) : (
                                    <ExpandMore />
                                  )}
                                </ListItem>
                                <MuiCollapse
                                  in={openMenus[`problem-${problem.id}`]}
                                  timeout="auto"
                                  unmountOnExit
                                >
                                  <List component="div" disablePadding>
                                    {uniqueSprinters.map((sprinter) => (
                                      <div key={sprinter.id} className="pl-4">
                                        <ListItem
                                          button
                                          onClick={() =>
                                            toggleMenu(
                                              `sprinter-${sprinter.id}`
                                            )
                                          }
                                        >
                                          {openMenus[
                                            `sprinter-${sprinter.id}`
                                          ] ? (
                                            <ExpandLess />
                                          ) : (
                                            <ExpandMore />
                                          )}
                                          {open && (
                                            <ListItemText
                                              primary={
                                                sprinter.name + "'s sprints"
                                              }
                                            />
                                          )}
                                        </ListItem>
                                        <MuiCollapse
                                          in={
                                            openMenus[`sprinter-${sprinter.id}`]
                                          }
                                          timeout="auto"
                                          unmountOnExit
                                        >
                                          <List component="div" disablePadding>
                                            {getSprintsBySprinter(
                                              problem.id,
                                              sprinter.name
                                            )?.map((sprint) => (
                                              <ListItem
                                                key={sprint.id}
                                                button
                                                onClick={() =>
                                                  toggleMenu(
                                                    `sprint-${sprint.id}`,
                                                    problem.id,
                                                    sprint
                                                  )
                                                }
                                                className="flex items-center justify-between px-3 py-2"
                                              >
                                                <div className="flex justify-between items-center space-x-2 w-full">
                                                  <CircularProgress
                                                    variant="determinate"
                                                    value={calculateSprintProgress(
                                                      sprint
                                                    )}
                                                    size={30}
                                                    thickness={5}
                                                    style={{
                                                      color: getStatusColor(
                                                        sprint.status
                                                      ),
                                                    }}
                                                  />
                                                  <div
                                                    className={`ml-2 pl-5 w-[80%] h-[33px] pt-1 rounded-full`}
                                                    style={{
                                                      backgroundColor:
                                                        getStatusColor(
                                                          sprint.status
                                                        ),
                                                      color: "#fff",
                                                    }}
                                                  >
                                                    {sprint.name}
                                                  </div>
                                                </div>
                                              </ListItem>
                                            ))}
                                          </List>
                                        </MuiCollapse>
                                      </div>
                                    ))}
                                  </List>
                                </MuiCollapse>
                              </div>
                            );
                          })}
                        </List>
                      </MuiCollapse>
                    </div>
                  )
                )}
              </List>
            ) : (
              // Sprint View
              <List component="nav" className="flex flex-col space-y-1">
                {Object.keys(userSprintsGrouped).map((problemName) => {
                  const { problemId, sprints } =
                    userSprintsGrouped[problemName];

                  // Calculate average progress for the problem's sprints
                  const totalProgress = sprints.reduce(
                    (sum, sprint) => sum + calculateSprintProgress(sprint),
                    0
                  );
                  const averageProgress =
                    sprints.length > 0 ? totalProgress / sprints.length : 0;

                  return (
                    <div
                      key={problemId}
                      className="flex flex-col border-b-[1px] border-[#cac4d0] w-full"
                    >
                      <ListItem
                        onClick={() =>
                          setOpenMenus((prev) => ({
                            ...prev,
                            [problemName]: !prev[problemName],
                          }))
                        }
                        className={`flex space-x-2 items-center cursor-pointer ${
                          openMenus[problemName]
                            ? "bg-bcolor rounded-lg text-white"
                            : "text-[#4a4459]"
                        } ${open ? "justify-start" : "justify-center"}`}
                      >
                        {open &&
                          (openMenus[problemName] ? (
                            <ExpandLess />
                          ) : (
                            <ExpandMore />
                          ))}
                        {open && (
                          <ListItemText
                            primary={problemName.substring(0, 20) + "..."}
                          />
                        )}
                        <div
                          className={`${
                            open ? "" : "flex justify-center w-full"
                          }`}
                        >
                          <CircularProgress
                            variant="determinate"
                            value={averageProgress}
                            size={30}
                            thickness={5}
                            style={{
                              color: openMenus[problemName]
                                ? "#ffffff"
                                : "#65558f",
                            }}
                          />
                        </div>
                      </ListItem>

                      {open && (
                        <MuiCollapse
                          in={openMenus[problemName]}
                          timeout="auto"
                          unmountOnExit
                        >
                          <List component="div" disablePadding>
                            {sprints.map((sprint) => (
                              <ListItem
                                key={sprint.id}
                                button
                                onClick={() =>
                                  toggleMenu(
                                    `sprint-${sprint.id}`,
                                    problemId,
                                    sprint
                                  )
                                }
                                className="flex items-center justify-between px-3 py-2"
                              >
                                <div className="flex justify-between items-center space-x-2 w-full">
                                  <CircularProgress
                                    variant="determinate"
                                    value={calculateSprintProgress(sprint)}
                                    size={30}
                                    thickness={5}
                                    style={{
                                      color: getStatusColor(sprint.status),
                                    }}
                                  />
                                  <div
                                    className={`ml-2 pl-5 w-[80%] h-[33px] pt-1 rounded-full`}
                                    style={{
                                      backgroundColor: getStatusColor(
                                        sprint.status
                                      ),
                                      color: "#fff",
                                    }}
                                  >
                                    {sprint.name}
                                  </div>
                                </div>
                              </ListItem>
                            ))}
                          </List>
                        </MuiCollapse>
                      )}
                    </div>
                  );
                })}
              </List>
            )}
          </div>
        )}

        <div className="flex-grow" />
        <NavLink to="/tutorial">
          <div
            className={`w-full h-[50px] bg-[#00adb5] flex items-center px-4 space-x-3 ${
              open ? "justify-start" : "justify-center"
            }`}
          >
            <HelpIcon style={{ color: "white" }} />
            {open && (
              <span className="text-white">Tutorials and Resources</span>
            )}
          </div>
        </NavLink>
        <NavLink to="/aboutus">
          <div
            className={`w-full h-[50px] bg-[#00adb5] flex items-center px-4 space-x-3 ${
              open ? "justify-start" : "justify-center"
            }`}
          >
            <ErrorIcon style={{ color: "white" }} />
            {open && <span className="text-white">About Us</span>}
          </div>
        </NavLink>
      </Drawer>
    </div>
  );
};

export default Sidebar;
